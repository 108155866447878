import * as React from "react";
import Head from "next/head";
import { AppProps } from "next/app";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { EmotionCache } from "@emotion/react";
import theme from "../src/theme";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { hotjar } from "react-hotjar";
import Script from "next/script";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp(props: MyAppProps) {
  const { Component, pageProps } = props;

  useEffect(() => {
    if (
      !(
        process.env.NEXT_PUBLIC_HOTJAR_HJID &&
        process.env.NEXT_PUBLIC_HOTJAR_HJSV
      )
    ) {
      return;
    }
    hotjar.initialize(
      parseInt(process.env.NEXT_PUBLIC_HOTJAR_HJID, 10),
      parseInt(process.env.NEXT_PUBLIC_HOTJAR_HJSV, 10)
    );
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <Component {...pageProps} />
        </QueryClientProvider>
      </ThemeProvider>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script id="google-analytics" strategy="lazyOnload">
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              page_path: window.location.pathname,
            });
                `}
      </Script>
    </>
  );
}
